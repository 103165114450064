<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content"  v-if="!viLoader">
      <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
      <!-- <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1> -->

      <div class="row mt-4 countsection" style="display:none">
        <div class="col-lg-12">
          <div class="countsect p-2 ml-2 mr-2">
            <ul class="dispflexhdr">
              <li>
                <span :class="getObjCounts.feeType > 0 ? 'badge contbadge' : 'badge contbadgedisb'">
                      <i :class="getObjCounts.feeType > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i>
                </span>Create Fee Type</li>
              <li><span :class="getObjCounts.feesPlan > 0 ? 'badge contbadge' : 'badge contbadgedisb'">
                      <i :class="getObjCounts.feesPlan > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i>
                      </span>Create Fee Plan</li>
             
            
              <li>
               <span :class="getObjCounts.adhocFees > 0 ? 'badge contbadge' : 'badge contbadgedisb'">
                      <i :class="getObjCounts.adhocFees > 0 ? 'fas fa-check' : 'fas fa-arrow-alt-circle-right'" ></i>
                      </span>Assign Fees to Classes / Students
              </li>
              <li>
                <span class="badge contbadgedisb"
                  ><i class="fas fa-arrow-alt-circle-right"></i></span
                >Fee Summary
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-4">
          <div
            class="white-box m-2 white-box1"
            style="padding: 0"
            @click.prevent="redirectRoute('/feesManagement/createFeeType')"
          >
            <div class="admcount_lft blu_bg">
              <span class="steps">Step 1</span>
              <span class="admcount org_bg">{{ getObjCounts.feeType }}</span
              ><span style="display: block">Create Fee Type</span>
            </div>
            <div class="admcount_rgt">
              <div class="triangle-left"></div>
              Create Fee Types for your Fee Plans
               <span><br />-------</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="white-box m-2 white-box1"
            style="padding: 0"
            @click.prevent="redirectRoute('/feesManagement/createPlan')"
          >
            <div class="admcount_lft blu_bg">
              <span class="steps">Step 2</span>
              <span class="admcount org_bg">{{ getObjCounts.feesPlan }}</span
              ><span style="display: block">Create Fee Plan</span>
            </div>
            <div class="admcount_rgt">
              <div class="triangle-left"></div>
              Create Fee plans for an Academic Year
              <span><br />[For e.g. Monthly, Quarterly, Half Yearly, Custom Plans, apply Discounts & Penalties]</span>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="white-box m-2 white-box1"
            style="padding: 0"
            @click.prevent="redirectRoute('/feesManagement/adhocfees')"
          >
            <div class="admcount_lft blu_bg">
              <span class="steps">Step 3</span>
              <span class="admcount org_bg">{{ getObjCounts.adhocFees }}</span
              ><span style="display: block">Create Adhoc Fees</span>
            </div>
            <div class="admcount_rgt">
              <div class="triangle-left"></div>
              Create Adhoc Fees for One/multiple Students or for any specific classes <br /><span
                >[For eg. Kindergarten, Lower Primary, Upper Primary, etc]</span
              >
            </div>
          </div>
        </div>
         
        <div class="col-md-4">
          <div
            class="white-box m-2 white-box1"
            style="padding: 0"
            @click.prevent="redirectRoute('/feesManagement/paymentGateWay')"
          >
            <div class="admcount_lft blu_bg">
              <span class="steps">Step 4</span>
              <span class="admcount org_bg">$</span
              ><span style="display: block">Payment Gateway</span>
            </div>
            <div class="admcount_rgt">
              <div class="triangle-left"></div>
              Razorpay's Free Payment Gateway <br /><span
                >Supports Netbanking, Credit, Debit Cards, UPI etc.</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div
            class="white-box m-2 white-box1"
            style="padding: 0"
            @click.prevent="redirectRoute('/feesManagement/invoice/feePlan')"
          >
            <div class="admcount_lft blu_bg">
              <span class="steps">Step 5</span>
              <span class="admcount org_bg">{{ getObjCounts.feesPlanGenerated }}</span
              ><span style="display: block">View Invoices</span>
            </div>
            <div class="admcount_rgt">
              <div class="triangle-left"></div>
              Invoice <br /><span
                >View/Edit Invoice at student level</span
              >
            </div>
          </div>
        </div>
         </div>
    </div>
     <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "vi-fees-management",
  data() {
    return {
    viLoader: false,
    count: {
        FeeType : 0,
        FeesPlan : 0,
        AdhocFees : 0,
        AssignedFeesType : 0,
        DiscountsAndPenalties : 0,
        FeesPlanGenerated: 0
     }
    }
  },
  created() {
    this.getAllObjectCounts();
  },
  computed: {
     styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      getObjCounts() {
      return this.$store.getters.getFeesManagementObjCount;
      },
     
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     async getAllObjectCounts() {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push('/login');
          } else {
             let isDataFetch = this.$store.getters.getFetchFeesManagementObjCount
             if(!isDataFetch || isDataFetch == undefined){
             this.viLoader = true
            const response = await ViService.viXGet(
              "/feesManagement/getAllCounts?viewType=list",
              userData.token
            );
            if (response.isSuccess) {
            
              const objResult = secureUI.secureGet(response.data);

              this.$store.dispatch('setFeesManagementObjCount', {

              feeType : objResult.FeeType ? objResult.FeeType : 0,
              feesPlan : objResult.FeesPlan ? objResult.FeesPlan : 0,
              adhocFees : objResult.AdhocFees ? objResult.AdhocFees : 0,
              assignedFeesType : objResult.AssignedFeesType ? objResult.AssignedFeesType : 0,
              discountsAndPenalties : objResult.DiscountsAndPenalties ? objResult.DiscountsAndPenalties : 0,
              feesPlanGenerated : objResult.FeesPlanGenerated ? objResult.FeesPlanGenerated : 0
              });

              this.$store.dispatch('setFetchFeesObjCount',true)

              this.$toasted.success(response.message);
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
            }
          }

             this.viLoader = false
          }
        }
  },
  components: {
      ViSpinner
    }
};
</script>